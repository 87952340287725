<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col v-if="false" cols="12" md="2" sm="6" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
            placeholder="Select Status.."
          />
        </b-col>
        <b-col cols="12" md="2" sm="6" class="mb-md-0 mb-2">
          <label>Meeting Type</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="meetingTypeFilter"
            :options="[
              { label: 'Meet', value: 'meet' },
              { label: 'Zoom', value: 'zoom' },
            ]"
            class="w-100"
            :reduce="(label) => label.value"
            label="label"
            @input="(val) => $emit('update:meetingTypeFilter', val)"
            placeholder="Select Type.."
            :searchable="false"
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Attendees</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="attendeesByFilter"
            :options="employeeListOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:attendeesByFilter', val)"
            placeholder="Select Employee.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Organizer</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="organizeByFilter"
            :options="employeeListOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:organizeByFilter', val)"
            placeholder="Select Employee.."
          />
        </b-col>
        <b-col cols="12" md="2" sm="2" class="mb-md-0 mb-2">
          <label>By Type</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeByFilter"
            :options="typeOptions"
            class="w-100"
             :reduce="(val) => val.value"
            @input="(val) => $emit('update:typeByFilter', val)"
            placeholder="Select Type.."
            :clearable="false"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    statusFilter: {},
    statusOptions: {
      type: Array,
      required: true,
    },
    employeeListOptions: {
      type: Array,
      required: true,
    },
    meetingTypeFilter: {},
    attendeesByFilter: {},
    organizeByFilter: {},
    typeByFilter: {},
  },
  data() {
    return {
      typeOptions: [
        { value: "upcoming", label: "Upcoming" },
        { value: "past", label: "Past" },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
