<template>
  <b-sidebar
    id="add-meeting-sidebar"
    ref="add_meeting_sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="resetFormData"
    width="60%"
    title="Scrollable Content"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="floating-closeright d-flex align-itms-center justify-content-center p-25" @click="confirmCloseButton">
        <feather-icon icon="XIcon" size="26" stroke="#101828" class="h-auto"/>
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom sticky-top"
      >
        <h3 class="mb-0 font-weight-bold">Create Meeting</h3>
        <div>
          <!-- <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
            pill
          >
            Cancel
          </b-button> -->
          <b-button
            pill
            variant="primary"
            @click="formSubmitted()"
            class="px-3"
          >
            Submit
          </b-button>
        </div>
      </div>

      <!-- BODY -->
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="meeting-form" class="p-2">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Meeting Name"
                  rules="required"
                >
                  <label class="required-l" for="name">Meeting Name</label>
                  <b-form-input
                    id="name"
                    v-model="name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="start_date_n_time"
                  >Starts at</label
                >
                <flat-pickr
                  id="start_date_n_time"
                  v-model="start_date_n_time"
                  class="form-control"
                  :config="{
                    minDate: 'today',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    altFormat: 'M j, h:iK',
                    altInput: true,
                  }"
                  @input="syncEndDate"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="end_date_n_time">Ends at</label>
                <flat-pickr
                  id="end_date_n_time"
                  v-model="end_date_n_time"
                  class="form-control"
                  :config="{
                    minDate: 'today',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    altFormat: 'M j, h:iK',
                    altInput: true,
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <b-form-select
                  v-model="recurrence"
                  :options="recurrence_option"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="12" class="" v-show="shift_ids.length < 1">
              <b-form-group>
                <label
                  >Attendees
                  <button
                    v-show="user_hash_ids.length > 0"
                    type="button"
                    class="btn btn-sm btn-danger mr-1"
                    @click="user_hash_ids = []"
                  >
                    Deselect All
                  </button>
                  <button
                    v-show="!user_hash_ids.includes(newUserData.hashid)"
                    type="button"
                    class="btn btn-sm btn-success"
                    @click="
                      user_hash_ids = [...user_hash_ids, newUserData.hashid]
                    "
                  >
                    Include Me
                  </button>
                </label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="user_hash_ids"
                  :options="employeeListOptions"
                  :clearable="false"
                  label="value"
                  class="w-100"
                  multiple
                  :reduce="(val) => val.id"
                  placeholder="Select Employee.."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Shift"
                label-for="shift"
                v-show="user_hash_ids.length < 1"
              >
                <v-select
                  v-model="shift_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="list_shifts"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="shift"
                  name="shift_ids"
                  placeholder="Select Shift"
                  multiple
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="video-conferencing"
                  >Add video conference</label
                >
                <v-select
                  v-model="video_conferencing"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    { id: 1, label: 'No', value: 'no' },
                    { id: 2, label: 'Meet', value: 'meet' },
                    { id: 3, label: 'Zoom', value: 'zoom' },
                  ]"
                  :reduce="(label) => label.value"
                  label="label"
                  input-id="video-conferencing"
                  placeholder="Select Type.."
                  :clearable="false"
                  :searchable="false"
                >
                </v-select>
              </b-form-group>

              <b-form-group
                v-if="video_conferencing == 'zoom' && zoom_access == false"
              >
                <button
                  class="btn btn-info rounded-pill"
                  type="button"
                  style="height: 40px"
                  @click="loginWithZoom"
                >
                  Login Zoom
                </button>
              </b-form-group>

              <b-form-group v-show="video_conferencing == 'zoom'">
                <label class="required-l" for="zoom-password"
                  >Zoom Password</label
                >

                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="zoom-password"
                    v-model="password"
                    name="password"
                    autofocus
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      icon="RefreshCwIcon"
                      @click="generate()"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Meeting Location"
                  rules=""
                >
                  <label class="" for="location">Meeting Location</label>
                  <b-form-input
                    id="location"
                    v-model="location"
                    name="location"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="description">
                  Description
                </label>

                <QuillInput
                  :value.sync="description"
                  :toolbar="true"
                  :mentionList="employeeListOptions"
                />
              </b-form-group>
            </b-col>

            <b-col md="12" class="">
              <b-form-group label="Poster" label-for="files">
                <file-pond
                  ref="poster_img"
                  name="poster_img"
                  max-file-size="5MB"
                  allow-paste="false"
                  max-files="1"
                  v-bind:files="poster_img"
                  image-preview-height="120px"
                  accepted-file-types="image/*"
                  file-validate-type-label-expected-types="Expects Image file"
                />
              </b-form-group>
            </b-col>

            <b-col md="12" class="">
              <b-form-group label="Attachments" label-for="files">
                <file-pond
                  ref="pond"
                  name="files[]"
                  max-file-size="50MB"
                  allow-paste="false"
                  max-files="10"
                  allow-multiple="true"
                  v-bind:files="myFiles"
                  image-preview-height="120px"
                  accepted-file-types="image/*,video/*,application/pdf"
                  file-validate-type-label-expected-types="Expects a PDF, Images, Video file"
                />
                <small class="text-warning flex items-center">
                  File size should be less than 50MB and allowed formats are
                </small>
                <p>( .mp4,.pdf, image)</p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import QuillInput from "@/components/QuillInput.vue";
import { bus } from "@/main.js";

import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      newUserData: this.$cookies.get("userData"),
      zoom_access: this.checksAccessToken(),
      name: "",
      location: "",
      start_date_n_time: moment().format("YYYY-MM-DD HH:mm"),
      end_date_n_time: moment().add(1, "hour").format("YYYY-MM-DD HH:mm"),
      description: "",
      video_conferencing: "meet",
      password: "",
      recurrence: "does_not_repeat",
      attachments: [],
      user_hash_ids: [],
      shift_ids: [],
      customFields: [],
      support_categories: [],
      priority_option: [],
      recurrence_option: [
        { text: "Does not repeat", value: "does_not_repeat" },
        { text: "Daily", value: "daily" },
      ],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      poster_img: [],
      employeeListOptions: [],
      list_shifts: [],
    };
  },
  computed: {},
  setup(myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarMeetingAddActive(val) {
      this.name = "";
      this.location = "";
      this.start_date_n_time = moment().format("YYYY-MM-DD HH:mm");
      this.end_date_n_time = moment().add(1, "hour").format("YYYY-MM-DD HH:mm");
      this.description = "";
      this.video_conferencing = "meet";
      this.password = "";
      this.recurrence = "";
      this.attachments = [];
      // this.$refs.add_meeting_sidebar.show();
      this.generate();
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
    start_date_n_time(val) {
      this.setRecurrenceOption(val);
    },
  },
  created() {
    this.generate();
    this.setRecurrenceOption(this.start_date_n_time);
  },
  mounted() {
    this.getCustomFields();
    this.fetchEmployeeList();
    this.fetchShiftsList();
  },
  methods: {
    syncEndDate() {
      this.end_date_n_time = moment(this.start_date_n_time)
        .add(1, "hour")
        .format("YYYY-MM-DD HH:mm");
    },
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form, if you proceed the form data will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$refs.add_meeting_sidebar.hide();
          this.resetFormData();
        }
      });
    },
    setRecurrenceOption(val) {
      this.isBusy = true;
      let day = moment(val, "YYYY-MM-DD HH:mm:ss").format("dddd");
      let date = moment(val, "YYYY-MM-DD HH:mm:ss").format("DD");
      let month = moment(val, "YYYY-MM-DD HH:mm:ss").format("MMMM");

      (this.recurrence_option = [
        { text: "Does not repeat", value: "does_not_repeat" },
        { text: "Daily", value: "FREQ=DAILY;" },
      ]),
        this.recurrence_option.push({
          text: "Weekly on " + day,
          value: "FREQ=WEEKLY;",
        });
      this.recurrence_option.push({
        text: "Monthly on " + day,
        value: "FREQ=MONTHLY;",
      });
      this.recurrence_option.push({
        text: "Annually on " + month + " " + date,
        value: "FREQ=YEARLY;",
      });
      this.recurrence_option.push({
        text: "Every weekday (Monday to Friday)",
        value: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR;",
      });
      this.isBusy = false;
    },
    fetchShiftsList() {
      const self = this;
      this.isBusy = true;
      let data = {};
      data.url = "list/shifts";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.list_shifts = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error);
        });
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    generate(characters = "a-z,A-Z,0-9", length = 10) {
      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < length; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
    },
    checksAccessToken() {
      if (this.$cookies.get("userData").zoom_linked === false) {
        return false;
      }
      return true;
    },
    loginWithZoom() {
      const self = this;
      var url =
        "https://zoom.us/oauth/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_ZOOM_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_ZOOM_REDIRECT_URI +
        "&state=" +
        self.newUserData.hashid;

      if (!self.zoom_access || !self.newUserData.zoom_linked) {
        var win = window.open(url, "Google", "width=600,height=600");

        var timer = setInterval(function () {
          if (win.closed) {
            clearInterval(timer);
            useJwt.getUserInfo().then((res) => {
              this.$cookies.set(
                "userData",
                res.data.data,
                60 * process.env.VUE_APP_LOGIN_TIMEOUT
              );
              self.newUserData = this.$cookies.get("userData");
              if (self.newUserData.zoom_linked) {
                self.zoom_access = true;
              }
            });
          }
        }, 1000);
      } else {
        self.zoom_access = true;
      }
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          self.channel_type_select = res.data.channel_type_select;
          self.identity_type_select = res.data.identity_type_select;
          self.feedback_type_select = res.data.feedback_type_select;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Something went wrong",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: error.toString(),
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    resetFormData() {
      console.log("reset");
      this.$refs.accountRules.reset();

      this.name = "";
      this.location = "";
      this.start_date_n_time = moment().format("YYYY-MM-DD HH:mm");
      this.end_date_n_time = moment().add(1, "hour").format("YYYY-MM-DD HH:mm");
      this.description = "";
      this.video_conferencing = "meet";
      this.password = "";
      this.recurrence = "does_not_repeat";
      this.attachments = [];
      this.user_hash_ids = [];
      this.shift_ids = [];
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("meeting-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      let files = self.$refs.pond.getFiles();
      let keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      formData.delete("poster_img"); // data is the name of the original input file; import to delete
      let poster_img = self.$refs.poster_img.getFiles();
      if (poster_img.length > 0) {
        formData.append("poster_img", poster_img[0].file);
      }

      formData.append("description", self.description);
      formData.append("start_date_n_time", self.start_date_n_time);
      formData.append("end_date_n_time", self.end_date_n_time);
      formData.append("video_conferencing", self.video_conferencing);
      formData.append("user_hash_ids", self.user_hash_ids);
      formData.append("shift_ids", self.shift_ids);
      formData.append("recurrence", self.recurrence);

      let data = {};
      data.url = "meetings";
      self.files = this.myFiles;
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          self.$refs.add_meeting_sidebar.hide();
          bus.$emit("loadMeetings");

          this.name = "";
          this.location = "";
          this.start_date_n_time = moment().format("YYYY-MM-DD HH:mm");
          this.end_date_n_time = moment().add(1, "hour").format("YYYY-MM-DD HH:mm");
          this.description = "";
          this.video_conferencing = "meet";
          this.password = "";
          this.recurrence = "does_not_repeat";
          this.attachments = [];
          this.user_hash_ids = [];
          this.shift_ids = [];

          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Meeting Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Meeting Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Meeting Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Meeting Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("meeting-form"));

      formData.append("status", self.status);
      formData.append("priority", self.priority);
      formData.append("category_id", self.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "posts/" + self.hashid;
      self._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this meeting to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.priority = "medium";
          self.status = "open";
          self.subject = " ";
          self.get_customfields_data = [];
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Meeting Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Meeting Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Meeting Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Meeting Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-posts/fetchPost", hashid)
        .then((res) => {
          this.hashid = res.data.data.hashid;
          this.name = res.data.data.name;
          this.location = res.data.data.location;
          this.password = res.data.data.password;
          this.video_conferencing = res.data.data.video_conferencing;
          this.description = res.data.data.description;
          this.poster_img = res.data.data.poster_img;
          this.start_date_n_time = res.data.data.start_date_n_time;
          this.end_date_n_time = res.data.data.end_date_n_time;
          this.user = res.data.data.user;
          this.attachments = res.data.data.attachments;
          this.status = res.data.data.status;
          this.isGoing = res.data.data.isGoing;
          this.attendees = res.data.data.attendees;
          this.accepted_attendees = res.data.data.accepted_attendees;
          this.attended_users = res.data.data.attended_users;
          this.join_url = res.data.data.join_url;
          this.shift_ids = res.data.data.shift_ids;
          this.user_hash_ids = res.data.data.user_hash_ids;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
