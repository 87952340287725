<template>
  <div class="w-100" @click="handleClickOutside" >
    <textarea :value="cleanContent" :name="name" :id="id" v-show="false">
    </textarea>
    <div  @click="handleEditorClick" @dblclick="showToolbar"
    @contextmenu.prevent="showToolbar">
      <quill-editor v-model="content" :content="value" :class="className" name="body" :options="editorOption"
      ref="myQuillEditor" @input="(val) => $emit('update:value', val)" >
      </quill-editor>
    </div>
    
    <div v-if="showEmojiPicker" class="emoji-picker-overlay" >
      <emoji-picker :ref="id+'emoji'" @emoji-click="addEmoji" ></emoji-picker>
    </div>       
  </div>
</template>

<script>
import "quill-mention/dist/quill.mention.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "emoji-picker-element";
import "quill-emoji/dist/quill-emoji.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { quillEditor } from "vue-quill-editor";
import ImageCompress from "quill-image-compress";
import Quill from "quill";
window.Quill = Quill;
import Mention from "quill-mention";
Quill.register("modules/mention", Mention);
// Quill.register("modules/imageCompress", ImageCompress);
import ImageResize from "quill-image-resize";
Quill.register("modules/imageResize", ImageResize);
import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);
import Vue from "vue";

const Link = Quill.import("formats/link");

class linkType extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (value.startsWith("https://") || value.startsWith("http://")) {
      node.className = "link--external";
      node.setAttribute("target", "_blank");
    } else {
      node.setAttribute("target", "_blank");
    }
    return node;
  }
}
Quill.register(linkType);

//AutoDetect URLs
import QuillAutoDetectUrl, {
  QuillAutoDetectUrlOptions,
} from "quill-auto-detect-url";

Quill.register("modules/autoDetectUrl", QuillAutoDetectUrl);

import $ from "jquery";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    quillEditor,
    QuillAutoDetectUrlOptions,
  },
  name: "QuillInput",
  props: {
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    toolbar: {
      required: true,
    },
    mentionList: {
      required: true,
    },
    name: { type: String, required: false, default: "" },
  },
  data() {
    return {
      startIndex:null,
      endIndex:null,
      body: this.value,
      lastPosition:0,
      videoURL:null,
      showEmojiPicker: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [

              ['undo', 'redo'],                            // Undo and redo
              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              // Basic text formatting

              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons


              // Text alignment
              [{ 'align': [] }],

              [{ 'list': 'ordered' }, { 'list': 'bullet' }],

              // Headers (continued)
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              // Indent and blockquote
              // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

              // Color and background
              // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme


              [{ 'blockquote': true }],

              // Link, image, video
              ['link', 'image', 'video'],

              // Code block
              ['code-block'],

              // Clean formatting
             ['clean']
            ],
            handlers: {
              emoji: this.toggleEmojiPicker,
              undo: () => this.undo(),
              redo: () => this.redo(),
              // emoji: () => this.triggerEmojiPicker(), // Custom emoji picker handler
              // image: () => this.triggerImageUpload(), // Custom image upload handler
              // video: () => this.triggerVideoEmbed()   // Custom video embed handler
              
            },
          },
          imageResize: {
            modules: ["Resize"],
            // displaySize: true,
          },
          mention: {
            mentionListClass: "ql-mention-list custom-mention-list",
            allowedChars: /^[a-zA-Z0-9_]*$/,
            mentionDenotationChars: ["@"],
            dataAttributes: [
              "id",
              "value",
              "denotationChar",
              "link",
              "hover",
            ],
            offsetLeft: $("body").hasClass("rtl") ? -250 : 0,
            renderItem: (item) => {
              return item.display;
            },
            source: (searchTerm, renderList, mentionChar) => {
              let values = this.mentionList;

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                    ~values[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            },
          },
          autoDetectUrl: true,
          // imageCompress: this.toolbar
          //   ? {
          //       quality: 0.8, // default
          //       maxWidth: 1000, // default
          //       maxHeight: 1000, // default
          //       imageType: "image/jpeg", // default
          //       debug: false, // default
          //       suppressErrorLogging: false, // default
          //       insertIntoEditor: undefined, // default
          //     }
          //   : false,
        },
        theme: "bubble", // or 'bubble'
        content: "",
        placeholder: this.placeholder,
        id: this.id,
      },
      content: "",
    };
  },
  created() {
    if (this.toolbar === "false") this.editorOption.modules.toolbar = false;

    this.content = this.value;
  },
  computed: {
    cleanContent() {
      if (this.content) {
        return this.content;
        let regex = /(<([^>]+)>)/gi;
        return this.content.replace(regex, "");
      }
      return "";
    },
  },
  watch:{
    content(val){
      const quill = this.$refs.myQuillEditor.quill;
      const range = quill.getSelection();
      if(range){
        this.lastPosition = range.index;
      }
    }
  },
  methods: {
    handleClickOutside(event) {
      // Check if the click is outside the div
      if (this.$refs[this.id+'emoji'] && !this.$refs[this.id+'emoji'].contains(event.target)) {
        this.outsideClick(); // Trigger your custom function
      }
    },
    outsideClick() {
      this.showEmojiPicker = false;
    },
    replaceTextInRange(target,replacement) {
      const quill = this.$refs.myQuillEditor.quill;

      // Get the text within the specified range
      const selectedText = quill.getText(this.startIndex, this.endIndex);

      // Check if the this.$refs[this.id+'emoji']selected text matches the target string
      if (selectedText === target) {
        // If matched, delete the text in the specified range
        quill.deleteText(this.startIndex, this.endIndex);

        // Insert the replacement text at the start index
        quill.insertText(this.startIndex, replacement);

        console.log("Text replaced successfully");
      } else {
        console.log("Selected text does not match the target string");
      }
    },
    handleEditorClick(){
      const quill = this.$refs.myQuillEditor.quill;
      const range = quill.getSelection();
      if(range){
        this.lastPosition = range.index;
      }
    },
    handleDoubleClick(event) {
      // Your function logic when double-click occurs
      console.log('Double-clicked!', event);
    },
    handleRightClick(event) {
      // Your function logic when right-click occurs
      console.log('Right-clicked!', event);
    },
    showToolbar() {
      const quill = this.$refs.myQuillEditor.quill;

      // Set the selection programmatically
      const range = quill.getSelection();
      if (range) {
        // quill.setSelection(range.index, 0); // Set selection to current index without moving it

        // Trigger the tooltip to show
        const tooltip = quill.theme.tooltip;
        tooltip.show();
        tooltip.position(quill.getBounds(range));
      }
    },
    toggleEmojiPicker() {
      
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    addEmoji(event) {
      const emoji = event.detail.unicode;
      const quill = this.$refs.myQuillEditor.quill;
      const range = quill.getSelection();
      if(range == null){
        quill.insertText(this.lastPosition, emoji);
        quill.setSelection(this.lastPosition + emoji.length);
        this.showEmojiPicker = false;
      }else{
        quill.insertText(range.index, emoji);
        quill.setSelection(range.index + emoji.length);
        this.showEmojiPicker = false;

      }
    },
    undo() {
      this.$refs.myQuillEditor.quill.history.undo();
    },
    redo() {
      this.$refs.myQuillEditor.quill.history.redo();
    },
    triggerImageUpload() {
      const quill = this.$refs.myQuillEditor.quill;
      const toolbar = quill.getModule('toolbar');
      
      // Access the image button in the toolbar
      const imageButton = toolbar.container.querySelector('.ql-image');
      if (imageButton) {
        imageButton.click(); // Trigger the click event on the image button
      } else {
        console.error('Image button not found in the toolbar');
      }
    },
    triggerVideoEmbedPopUP(){
      this.$bvModal.show("upload-video");
    },
    embedVideoURL(url) {
      if(url){
        const range = this.$refs.myQuillEditor.quill.getSelection();
        if(range == null){
          this.$refs.myQuillEditor.quill.insertEmbed(this.lastPosition, 'video', url);
        }else{
          this.$refs.myQuillEditor.quill.insertEmbed(range.index, 'video', url);
        }
      }else{
        alert('No url');
      }
    },
    triggerEmojiPicker() {
      // Trigger the emoji picker (assumes you have an emoji module integrated)
      this.$refs.myQuillEditor.quill.getModule('toolbar').handlers.emoji();
    },
    getSelectedText(){
      const range = this.$refs.myQuillEditor.quill.getSelection();
      
      if (range && range.length > 0) {
        this.startIndex = range.index;
        this.endIndex = range.length;
        const selectedText = this.$refs.myQuillEditor.quill.getText(range.index, range.length);
        return selectedText;
      } else {
        return '';
      }
      return '';
    }
  },
};
</script>

<style>
.quill-editor-wrapper {
  position: relative;
}

.emoji-picker-overlay {
  position: absolute;
  bottom: 60px;
  right: 10px;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ql-toolbar .ql-emoji::before {
  content: "😊";
  cursor: pointer;
}

.ql-toolbar .ql-emoji:hover::before {
  background-color: red !important;
}

.ql-undo::before {
  content: '↶'; /* or use a custom icon */
}

.ql-redo::before {
  content: '↷'; /* or use a custom icon */
}

</style>
