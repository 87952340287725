<template>
  <div>
    <!-- Filters -->
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <MeetingListFilters
          :status-filter.sync="statusFilter"
          :status-options="meetingStatusOption"
          :meeting-type-filter.sync="meetingTypeFilter"
          :organize-by-filter.sync="organizeByFilter"
          :employee-list-options="employeeListOptions"
          :attendees-by-filter.sync="attendeesByFilter"
          :type-by-filter.sync="typeByFilter"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="meetingsMetaData.length && $can('meeting_delete')"
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedMeetings.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge round">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search Name"
                />
              </b-input-group>
              <b-button
                variant="primary"
                v-if="$can('meeting_create')"
                class="ml-1"
                v-b-toggle.add-meeting-sidebar
                pill
              >
                <span class="text-nowrap">Create Meeting</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('meeting_delete')"
              class="float-right"
              variant="danger"
              :disabled="meetingsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ meetingsDeleteCount }} Meeting</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedMeetings"
        name="selectedMeetings"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchMeetingList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap text-center">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <template #cell(start_date_n_time)="data">
            <div class="text-nowrap">
              {{ data.item.start_date_n_time | formatDateTime }}
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('meeting_show')"
                @click="editRecord(data.item)"
                v-b-toggle.view_meeting_sidebar
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                v-b-tooltip.hover.topright.v-primary
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!-- Column: User -->
          <template #cell(user.name)="data">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.user.avatar"
                  :text="avatarText(data.item.user.name)"
                />
              </template>
              <b-link
                v-if="$can('employee_show')"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.user.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.user.name"
              >
                {{ data.item.user.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                class="d-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.user.name"
              >
                {{ data.item.user.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status == "1" ? "Active" : "In Active" }}
            </b-badge>
          </template>
          <template #cell(join_url)="data">
            <div v-if="data.item.join_url" class="">
              <b-button
                variant="info"
                target="_blank"
                size="sm"
                @click="open_join_url(data.item.join_url, data.item.hashid)"
              >
                Join Meeting
              </b-button>
              <b-button
                type="button"
                size="sm"
                v-clipboard:copy="joinUrl(data.item.join_url, data.item.hashid)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                variant="flat-secondary"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </template>
          <template #cell(attendees)="data">
            <div class="d-flex align-items-center">
              <b-avatar-group size="34" class="ml-1">
                <b-avatar
                  v-for="(user, i) in data.item.attendees"
                  v-if="i < 5"
                  :key="i"
                  v-b-tooltip.hover.bottom="user.name"
                  class="pull-up m-0"
                  :src="user.avatar"
                />
              </b-avatar-group>
              <b-link
                v-if="data.item.attendees.length > 0"
                class="text-primary text-nowrap ml-50"
                v-b-modal="'show-attendees-' + data.item.hashid"
              >
                {{ data.item.attendees.length }} member.
              </b-link>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data.item)"
              v-b-toggle.edit-meeting-sidebar
              v-if="$can('meeting_edit')"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="editRecord(data.item)"
              v-if="$can('meeting_show')"
              v-b-tooltip.hover
              v-b-toggle.view_meeting_sidebar
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('meeting_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedMeetings.length == 0"
              v-model="currentPage"
              :total-rows="totalMeetingList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('meeting_delete')"
              class="float-right"
              variant="danger"
              :disabled="meetingsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ meetingsDeleteCount }} Meeting</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <MeetingAdd />
    <MeetingEdit :item-id.sync="meetingId" />
    <MeetingView :item-id.sync="meetingId" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import meetingStoreModule from "../meetingStoreModule";
import useMeetingList from "./useMeetingList";
import MeetingListFilters from "./MeetingListFilters.vue";
import MeetingAdd from "./MeetingAdd.vue";
import MeetingEdit from "./MeetingEdit.vue";
import MeetingView from "./MeetingView.vue";
import { bus } from "@/main.js";

export default {
  components: {
    MeetingListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    ToastificationContent,
    BFormCheckbox,
    BFormCheckboxGroup,
    MeetingView,
    MeetingAdd,
    MeetingEdit,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-meetings";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedMeetings = ref([]);
    const meetingsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, meetingStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchMeetingList,
      tableColumns,
      perPage,
      currentPage,
      totalMeetingList,
      meetingsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      meetingTypeFilter,
      organizeByFilter,
      attendeesByFilter,
      typeByFilter,
      filterByMeetingUrgencyStatus,
      filterByMeetingType,
      resolveUserStatusVariant,
      meetingStatusOption,
    } = useMeetingList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedMeetings.value = [];
      if (val) {
        meetingsMetaData.value.forEach((meeting) => {
          selectedMeetings.value.push(meeting.hashid);
        });
      }
    });

    watch(selectedMeetings, (val) => {
      meetingsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchMeetingList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalMeetingList,

      // Filter
      avatarText,

      // Extra Filters
      statusOptions,
      statusFilter,
      filterByMeetingUrgencyStatus,
      filterByMeetingType,
      selectedMeetings,
      meetingsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      meetingStatusOption,
      meetingTypeFilter,
      organizeByFilter,
      attendeesByFilter,
      typeByFilter,
      meetingsMetaData,
    };
  },
  data() {
    return {
      meetingId: "",
      employeeListOptions: [],
    };
  },
  created() {
    // if (!this.$can("referral_delete")) {
    //   this.tableColumns = this.tableColumns.filter(
    //     (item) => item.key != "select"
    //   );
    // }
    bus.$on("loadMeetings", () => {
      console.log("Events : loadMeetings");
      this.refetchData();
    });
    this.fetchEmployeeList();
  },
  methods: {
    joinUrl(url, hash_id) {
      let new_url = this.$router.resolve({
        path: "/join",
        query: { type: "meeting", hash_id: hash_id, url: url },
      });
      return window.location.origin + new_url.href;
    },
    open_join_url(url, hash_id) {
      let data = {};
      data.url = url;

      let routeData = this.$router.resolve({
        path: "/join",
        query: { type: "meeting", hash_id: hash_id, url: url },
      });
      return window.open(routeData.href, "_blank");
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List faile',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    onCopy() {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Text copied",
      //     icon: "BellIcon",
      //   },
      // });
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
    },
    onError() {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Failed to copy texts!",
      //     icon: "BellIcon",
      //   },
      // });
      this.$toast({
              component: ToastificationContent,
              props: {
                title: `Failed to copy texts!`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
    },
    editRecord(record) {
      this.meetingId = record.hashid;
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedMeetings.length} Meeting, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-meetings/removeMultipleMeetings",
              this.selectedMeetings
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedMeetings = [];
              if (res.data.success) {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Meeting Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: "Meeting Deletion failed.",
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchMeetingsData() {},
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-meetings/removeMeeting", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Meeting Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Meeting Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Meeting Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Meeting Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Meeting Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Meeting Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Meeting Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Meeting Deletion failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
